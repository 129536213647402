import { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import { getRequest } from "../utils/api-requests";
import { queueDBtoData } from "../utils/conversions";
import { Boundries, DBQueueEntry, DBResponse, ListFuncs, QueueEntry } from "../utils/interfaces";
import { ListItem } from "./ListItem";

import pathToArrowDown from "../icons/arrow-down.svg";

interface ListProps {
    min_donate : number;
    max_display : number;
    font_size : string;
}

const List = forwardRef<ListFuncs, ListProps>(({ min_donate, max_display, font_size}, ref) => {
    const [queueData, setQueueData] = useState<QueueEntry[]>([]);
    const [listComponents, setListComponents] = useState<JSX.Element[]>([]);
    const [minDonate, setMinDonate] = useState<number>(min_donate);
    const [maxDisplay, setMaxDisplay] = useState<number>(max_display);
    const [displayBoundaries, setDisplayBoundaries] = useState<Boundries>({ left : 0, right : 0});
    const [currentQueueNumber, setCurrentQueueNumber] = useState<number>(0);
    const [visibleCount, setVisibleCount] = useState<number>(0);
    const [curFontSize, setCurFontSize] = useState<string>(font_size);

    function updateQueueData(data: DBResponse){
        if(data.songs[0]){
            setCurrentQueueNumber(parseInt(data.songs[data.current].queue_number));
            let count = 0;
            for(let i = 0; i < data.songs.length; i++){
                if(data.songs[i].visible){
                    data.songs[i].queue_number = count.toString();
                    count++;
                };
            };
            setVisibleCount(count);
            setQueueData(data.songs.map((song: DBQueueEntry) => queueDBtoData(song)));
        };
    };
    
    useImperativeHandle(ref, () => ({
        getQueue(){
            getRequest('queue', '5100')
                .then(response => response.json())
                .then(data =>{
                    updateQueueData(data);
                    setMinDonate(data.min_donate);
                    setMaxDisplay(data.max_display);
                });
            
        },
        updateListData(data: DBResponse){
            updateQueueData(data);
        },
        updateLikes(count: number, index: number){
            setQueueData(oldQueueData => {
                let newQueueData = [...oldQueueData];
                newQueueData[index].like_count = count;
                return newQueueData;
            })
        }
    }));

    function calcBoundaries(maximumDisplay: number, currentNumber: number, overallLength: number): Boundries{
        let division = Math.floor(maximumDisplay/2);
        let left = currentNumber - division;
        let right = currentNumber + division;
        if(currentNumber >= 0){
            if(maximumDisplay % 2 === 0){
                left++;
            };
            if(left < 0){
                right -= left;
                left = 0;
            };
            if(right >= overallLength){
                left = overallLength - maximumDisplay;
                right = overallLength - 1;
            };
        }else{
            left = 0;
            right = -1;
        }
        return {left: left, right: right};
    };

    useEffect(() => {
        setMinDonate(min_donate);
    },[min_donate]);

    useEffect(() => {
        setMaxDisplay(max_display);
    },[max_display]);

    useEffect(() => {
        setCurFontSize(font_size);
    },[font_size]);

    useEffect(() =>{
        setDisplayBoundaries(calcBoundaries(maxDisplay, currentQueueNumber, visibleCount));
    },[maxDisplay, currentQueueNumber, visibleCount]);

    useEffect(() => {
        setListComponents(queueData.filter(entry => entry.visible && (entry.queue_number >= displayBoundaries.left && entry.queue_number <= displayBoundaries.right)).map(entry => {
            return (<ListItem queueData={entry} key={entry.id}/>)
        }));
    },[queueData, minDonate, displayBoundaries]);

    if(visibleCount === 0){
        return (<div className="empty-list" style={{fontSize: curFontSize}}>Заказы открыты :D</div>)
    }

    return (<div style={{fontSize: curFontSize}} className="list">{listComponents}{(displayBoundaries.right < (visibleCount-1)) && <img className="arrow-down" alt="arrow pointing down" src={pathToArrowDown}/>}</div>);
});

export default List;