import React, { useCallback, useEffect, useState } from 'react'
import { QueueEntry } from '../utils/interfaces';

import pathToThumbsUp from '../icons/thumbs-up-white.svg';
import pathToThumbsDown from '../icons/thumbs-down-white.svg';
import pathToArrowRight from '../icons/arrow-right.svg';

interface ListItemProps {
    queueData: QueueEntry;
}

export const ListItem: React.FC<ListItemProps> = ({ queueData }) => {
    const [curLike, setCurLike] = useState<string>(pathToThumbsUp);
    const [curState, setCureState] = useState<string>('not-played');
    const changeLikeImg = useCallback(() => {
        setCurLike(queueData.like_count < 0 ? pathToThumbsDown : pathToThumbsUp);
    },[queueData.like_count]);

    const changeState = useCallback(() =>{
        if(queueData.played){
            setCureState('played');
        }else if(queueData.current){
            setCureState('current');
        }else{
            setCureState('not-played');
        }
    },[queueData])
    
    useEffect(() =>{
        changeLikeImg();
    },[changeLikeImg]);

    useEffect(() =>{
        changeState();
    },[changeState]);

    return (
        <div className='list-item'>
            <div className={`song-info ${curState}`}>
                {queueData.current ? <img className='arrow-left' src={pathToArrowRight} alt='arrow pointing right'/> : <div className='number'>{queueData.queue_number+1}</div>} {queueData.artist} - {queueData.song_name}
            </div> 
            <div className='like-text'>
                <img src={curLike} alt='Like'/> {queueData.like_count}
            </div>
        </div>
    );
}