import React, { useEffect, useState } from 'react'

interface InfoBlockProps {
    show_info: boolean;
    info_text: string;
    font_size: string;
}

export const InfoBlock: React.FC<InfoBlockProps> = ({ show_info, info_text, font_size}) => {
    const [showInfo, setShowInfo] = useState<boolean>(show_info);
    const [infoText, setInfoText] = useState<string>(info_text);
    const [curFontSize, setCurFontSize] = useState<string>(font_size);

    useEffect(() => {
        setShowInfo(show_info);
    },[show_info]);

    useEffect(() => {
        setInfoText(info_text);
    },[info_text]);

    useEffect(() => {
        setCurFontSize(font_size);
    },[font_size]);
        
    return (
        <div style={{fontSize: curFontSize}} className='info-block'>
            {showInfo && 
                <p>
                    {infoText}
                </p>}
        </div>
    );
};
