import { useEffect, useState, useRef } from 'react';
import { io } from 'socket.io-client';
import { InfoBlock } from './components/InfoBlock';
import List from './components/List'
import './css/App.css';
import { BACKEND_ADDRESS, getRequest } from './utils/api-requests';
import { ListFuncs } from './utils/interfaces';

function App() {
    const [showInfo, setShowInfo] = useState<boolean>(false);
    const [infoText, setInfoText] = useState<string>('');
    const [curFontSize, setCurFontSize] = useState<string>('2rem');
    const [minDonate, setMinDonate] = useState<number>(0);
    const [maxDisplay, setMaxDisplay] = useState<number>(0);

    const listFuncs = useRef<ListFuncs>(null);
    const SERVER_URL = `https://${BACKEND_ADDRESS}:5200`;

    function getQueue(){
        if(listFuncs.current){
            listFuncs.current.getQueue();
        }
    }

    useEffect(() => {
        if(listFuncs.current){
            const socket = io(SERVER_URL);
            socket.emit('sub admin', 'overlay');
            socket.on('queue change', (data) => {
                if(listFuncs.current){
                    listFuncs.current.updateListData(data);
                }
            });
            socket.on('song likes change', ({count, song_index}) => {
                if(listFuncs.current){
                    listFuncs.current.updateLikes(count, song_index);
                }
            });
            socket.on('queue entry change', getQueue);
            socket.on('new current', getQueue);
            socket.on('queue entry add', getQueue);
            socket.on('queue entry delete', getQueue);
            socket.on('queue order update', getQueue);
            socket.on('min donate amount changed', (data) => {
                setMinDonate(data);
            });
            socket.on('max display changed', (data) => {
                setMaxDisplay(data);
            });
            socket.on('font size changed', (data) => {
                setCurFontSize(data);
            });
            socket.on('show info text', (data) => {
                setShowInfo(data);
            });
            socket.on('change info text', (data) => {
                setInfoText(data);
            });
            socket.on('font size changed', (data) => {
                setCurFontSize(data);
            });
            return (() => {
                socket.disconnect();
            });
        }
    }, [SERVER_URL]);

    useEffect(()=>{
        getRequest('admin/info', '5100')
            .then(response => response.json())
            .then(data => {
                setShowInfo(data.showInfo);
                setInfoText(data.textInfo);
                setCurFontSize(data.fontSize);
            });
        getQueue();
    },[]);

    return (
        <div className="App">
            <List font_size={curFontSize} max_display={maxDisplay}  min_donate={minDonate} ref={listFuncs} />
            <InfoBlock font_size={curFontSize} info_text={infoText} show_info={showInfo}/>
        </div>
    );
}

export default App;
